<template>
  <div class="index body fixed mobile">
    <router-view name="NotificationBar"></router-view>
    <!-- 橫向提示 -->
    <div class="landscape flexH width height middle">
      <div class="icon white">
       <font-awesome-icon icon="fa-solid fa-rotate-right"  size="6x" />
      </div>
      <div class="flexV">
        <span class="fXXL white">請直向使用</span>
        <span class="fL secondary">目前只支援直向使用<br>
          請把手機旋轉至直立方向</span>
        </div>
    </div>
    <router-view name="Header"></router-view>
    <router-view></router-view>
    <router-view :routeTo="routeTo" name="Footer"></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { db } from "@/db.js";
import moment from "moment-timezone";

export default {
  name: "AppLayout",
  data() {
    return {
      routeTo: this.$route.path,
      notificationPopupEnabled: false,
    };
  },
  computed: {
    ...mapState(["user", "userInfo"]),
  },
  mounted() {
    console.log(
      "mounted: ",
      process.env.VUE_APP_HOST,
      `${process.env.BASE_URL}`,
      process.env.VUE_APP_ENV
    );
    console.log("lang: ", localStorage.getItem("lang"));

    //NOTE: Howard: 在這裡建立通知message listener
    this.initNotificationListener();
    setTimeout(() => {
      this.notificationPopupEnabled = true;
    }, 3000);
  },
  methods: {
    //NOTE: Howard: 通知message listener。不分種類。
    //FIXME: 可能之後會需要依通知種類切換popup style
    initNotificationListener() {
      if (this.user && this.user.user) {
        // console.log('initNotificationListener: entered', this.user.user.firestoreChannelId);
        db.collection(
          `notifications/${process.env.VUE_APP_MERCHANT_ID}/topics/${this.user.user.firestoreChannelId}/messages`
        )
          .where("unread", "==", true)
          .orderBy("ts", "desc")
          .onSnapshot((snapshot) => {
            snapshot.docChanges().forEach((change) => {
              console.warn(
                `initNotificationListener: got a new notification change`,
                change.type
              );

              const data = change.doc.data();

              if (change.type === "added") {
                const dateTime = moment
                  .unix(data.ts)
                  .format("YYYY/M/D h:mm:ssA");

                if (!this.notificationPopupEnabled) return;
                console.log('this.$store.getters.muteNotifications', this.$store.getters.muteNotifications);
                if (this.$store.getters.muteNotifications === true) return;
                this.$notify({
                  group: "noti-popup",
                  title: data.title,
                  text: `${data.text}<br>
                            <span style="color:grey">${dateTime}</span>
                            `,
                  foo: "bar",
                });
              }
            });
          });
      }
    },
  },
};
</script>

<style src="@/assets/css/app.css"></style>
<style src="@/assets/css/unique.css"></style>
